import { BigNumber } from '@ethersproject/bignumber';

// Constants
import {
  CHAINLINK_PRICE_DECIMALS,
  DEFAULT_COINS_UI_DECIMALS,
  STABLE_COINS_UI_DECIMALS,
  TEN,
  FIAT_DECIMALS,
  SATOSHI_DECIMALS,
  STABLECOIN_DECIMALS,
  WEI_DECIMALS,
} from 'Constants';

// Types
import { AssetBasicFragment as AssetV2 } from 'Graphql/schema-v2';

export const getAssetDecimalsUI = (symbol: string | undefined): number => {
  switch (symbol) {
    case 'USDC':
    case 'USDCk1':
    case 'USDC-MPL':
    case 'USDT':
    case 'DAI':
    case 'sDAI':
    case 'kycUSDC':
      return STABLE_COINS_UI_DECIMALS;
    default:
      return DEFAULT_COINS_UI_DECIMALS;
  }
};

const DECIMALS_MAP: { [key: string]: number } = {
  ADA: STABLECOIN_DECIMALS,
  AAVE: WEI_DECIMALS,
  AVAX: WEI_DECIMALS,
  BNB: WEI_DECIMALS,
  BTC: SATOSHI_DECIMALS,
  ETH: WEI_DECIMALS,
  FIL: WEI_DECIMALS,
  GBTC: FIAT_DECIMALS,
  kycUSDC: STABLECOIN_DECIMALS,
  kycWETH: WEI_DECIMALS,
  INJ: WEI_DECIMALS,
  'Legacy MPL': WEI_DECIMALS,
  LINK: WEI_DECIMALS,
  MapleToken: WEI_DECIMALS,
  MapleTokenV2: WEI_DECIMALS,
  PT_sUSDE: WEI_DECIMALS,
  SOL: WEI_DECIMALS,
  USDC: STABLECOIN_DECIMALS,
  USDCk1: STABLECOIN_DECIMALS,
  'USDC-MPL': STABLECOIN_DECIMALS,
  USDT: STABLECOIN_DECIMALS,
  WBTC: SATOSHI_DECIMALS,
  WETH: WEI_DECIMALS,
  WSTETH: WEI_DECIMALS,
  XRP: STABLECOIN_DECIMALS,
  OrcaLP_PYUSDC: WEI_DECIMALS,
  xMPL: WEI_DECIMALS,
  sDAI: WEI_DECIMALS,
};

export const getAssetDecimals = (symbol: string): number => {
  return DECIMALS_MAP[symbol] || STABLECOIN_DECIMALS;
};

export const isStablecoin = (symbol: string): boolean => {
  if (['USDC', 'USDCk1', 'USDC-MPL', 'USDT', 'kycUSDC'].includes(symbol)) return true;

  return false;
};

export const isWETH = (symbol: string): boolean => {
  if (['WETH', 'kycWETH'].includes(symbol)) return true;

  return false;
};

export const getUsdcValueByAsset = (amount: BigNumber, asset: AssetV2): BigNumber => {
  if (isStablecoin(asset.symbol)) return amount;

  const assetPrice = BigNumber.from(asset.price || '0');

  // WETH (18 decimals) = 8 (Chainlink) + 18 - 6 = 20
  // WBTC (8 decimals) = 8 (Chainlink) + 8 - 6 = 10
  const decimalsAdjustment = TEN.pow(CHAINLINK_PRICE_DECIMALS + +asset.decimals - STABLECOIN_DECIMALS);

  return amount.mul(assetPrice).div(decimalsAdjustment);
};
